<template>
  <div
    class="row csn-user-newsletter csn-user-account-section"
    id="user-account"
  >
    <div class="col-md-12">
      <div class="container">
        <section>
          <h1>{{ t('newsletter') }}</h1>
          <p>{{ t('description') }}</p>
        </section>
        <section v-if="IS_NEWSLETTER_DICTIONARY_PENDING">
          <Loader />
        </section>
        <section v-else>
          <h2>{{ t('stay_updated') }}</h2>
          <Form class="csn-user-newsletter-form" :ref="formRef">
            <Message :type="messageType" :message="message" :hasBottom="true" />
            <div class="row-fluid">
              <ToggleField
                :name="name.EMAIL"
                :label="t('email_label')"
                v-model="formData[name.EMAIL]"
              />
              <ToggleField
                :name="name.SMS"
                :label="t('sms_label')"
                v-model="formData[name.SMS]"
              />
              <div class="form-group">
                <button
                  type="submit"
                  class="btn casino-btn-ver-1 btn"
                  @click="submitForm"
                  :disabled="isSubmitting"
                >
                  <ButtonLoader v-if="isSubmitting" />
                  <span v-else>{{ t('update_bttn_label') }}</span>
                </button>
              </div>
            </div>
          </Form>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { formMixin } from '@/mixins'
import {
  USER_NEWSLETTER,
  Module,
  NEWSLETTER_DICTIONARY,
  SET_NEWSLETTER_DICTIONARY,
  IS_NEWSLETTER_DICTIONARY_PENDING,
} from '@/constants'
import { AuthApi } from '@/api'

const USER_NEWSLETTER_REQUEST = 'user_newsletter'
const name = {
  EMAIL: 'is_subscribed_to_newsletter',
  SMS: 'is_subscribed_to_mobile_marketing',
}

const formData = {
  [name.EMAIL]: false,
  [name.SMS]: false,
}

export default {
  name: USER_NEWSLETTER,
  mixins: [formMixin],
  components: {
    Form: () => import('@/components/FormData'),
    ToggleField: () => import('@/components/ToggleField'),
    ButtonLoader: () => import('@/components/ButtonLoader'),
    Message: () => import('@/components/atoms/Message'),
    Loader: () => import('@/components/Loader'),
  },
  data: () => ({
    formData: { ...formData },
  }),
  computed: {
    ...mapState(Module.NEWSLETTER, [
      NEWSLETTER_DICTIONARY,
      IS_NEWSLETTER_DICTIONARY_PENDING,
    ]),
    name: () => name,
    t() {
      return this.$createComponentTranslator(USER_NEWSLETTER)
    },
  },
  watch: {
    formData: {
      deep: true,
      handler() {
        this.message && (this.message = null)
        this.messageType && (this.messageType = null)
      },
    },
    IS_NEWSLETTER_DICTIONARY_PENDING(isPending) {
      if (!isPending) {
        this.formData = {
          [name.EMAIL]: this.NEWSLETTER_DICTIONARY[name.EMAIL],
          [name.SMS]: this.NEWSLETTER_DICTIONARY[name.SMS],
        }
      }
    },
  },
  methods: {
    ...mapActions(Module.NEWSLETTER, [SET_NEWSLETTER_DICTIONARY]),
    async submitForm() {
      const { isValid } = this.$refs[this.formRef].getValidation()

      if (!isValid) {
        return
      }

      try {
        const response = await AuthApi.postUserNewsletter({
          [USER_NEWSLETTER_REQUEST]: {
            [name.EMAIL]: this.formData[name.EMAIL],
            [name.SMS]: this.formData[name.SMS],
          },
        })

        if (!this.validateResponse(response)) {
          return
        }

        const { state, msg } = response

        if (state === this.responseState.OK) {
          await this.SET_NEWSLETTER_DICTIONARY()

          this.messageType = this.messageTypeEnum.SUCCESS
          this.message = msg
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isSubmitting = false
      }
    },
  },
  created() {
    this.formData[name.EMAIL] = this.NEWSLETTER_DICTIONARY[name.EMAIL]
    this.formData[name.SMS] = this.NEWSLETTER_DICTIONARY[name.SMS]
  },
}
</script>
